<template>
  <div id="app">
    <ValidationProvider
        :name="validateName"
        v-slot="{ errors }"
    >
      <ckeditor
          spellcheck="false"
          :editor="editor"
          :value="content"
          :config="editorConfig"
          @input="changeContent"
      ></ckeditor>
      <span class="validate-error text-danger">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor";
import UploadAdapter from "@ckeditor/ckeditor5-adapter-ckfinder/src/uploadadapter";
// import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import UnderLine from "@ckeditor/ckeditor5-basic-styles/src/underline";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import CKFinder from "@ckeditor/ckeditor5-ckfinder/src/ckfinder";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation";
// import MathType from '@wiris/mathtype-ckeditor5/src/plugin';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript';
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript';
// import List from "@ckeditor/ckeditor5-list/src/list";
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import JwtService from "@/common/jwt.service";
import HtmlEmbed from '@ckeditor/ckeditor5-html-embed/src/htmlembed';
import ImageRemoveEventCallbackPlugin from 'ckeditor5-image-remove-event-callback-plugin';
import {DELETE_IMAGE} from "@/store/image.module"; // Add this

export default {
  props: {
    slug: String,
    content: String,
    validateName: String,
    fileName: String
  },
  computed: {
    // cssProps() {
    //   return {
    //     '--height': this.height
    //   }
    // }
  },
  methods: {
    changeContent(value) {
      this.$emit("getData", value);
    }
  },
  data: function () {
    return {
      // height:"500px",
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        scayt_autoStartup: true,
        disableNativeSpellChecker: false,
        extraAllowedContent : 'img[width,height]',
        allowedContent: true,
        placeholder: "Nhập mô tả",
        plugins: [
          HtmlEmbed,
          ListStyle,
          Subscript, Superscript,
          EssentialsPlugin,
          Alignment,
          FontSize,
          FontFamily,
          FontColor,
          FontBackgroundColor,
          UploadAdapter,
          // Autoformat,
          BoldPlugin,
          ItalicPlugin,
          Strikethrough,
          UnderLine,
          Heading,
          CodeBlock,
          CKFinder,
          EasyImage,
          SimpleUploadAdapter,
          Image,
          ImageCaption,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          LinkPlugin,
          Table,
          TableToolbar,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          TextTransformation,
          // MathType,
          RemoveFormat,
          ImageRemoveEventCallbackPlugin
        ],

        toolbar: [
          "htmlEmbed",
          "heading",
          "|",
          "fontfamily",
          "fontsize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          'subscript',
          'superscript',
          "|",
          "alignment",
          "|",
          "numberedList",
          "bulletedList",
          "|",
          "indent",
          "outdent",
          "|",
          "link",
          "imageUpload",
          "insertTable",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
          "|",
          // "MathType",
          // "ChemType",
          "|",
          'removeFormat'
          // "ckfinder"

        ],
        fontColor: {
          colors: [
            {
              color: '#000000',
            },
            {
              color: '#FFFFFF',
            },
            {
              color: '#FF0000',
            },
            {
              color: '#00FF00',
            },
            {
              color: '#0000FF',
            },
            {
              color: '#FFFF00',
            }, {
              color: '#00FFFF',
            }, {
              color: '#FF00FF',
            },
            {
              color: '#C0C0C0',
            },
            {
              color: '#808080',
            },
            {
              color: '#800000',
            },
            {
              color: '#808000',
            },
            {
              color: '#008000',
            },
            {
              color: '#800080',
            }, {
              color: '#008080',
            }, {
              color: '#000080',
            },
            {
              color: '#3366FF',
            }, {
              color: '#007700',
            }, {
              color: '#330099',
            }, {
              color: '#FF33CC',
            },
            {
              color: '#33CC66',
            }, {
              color: '#FF9900',
            },
            {
              color: '#663300',
            },
            {
              color: '#006699',
            },

            // ...
          ],

          // Display 6 columns in the color grid.
          columns: 6,
        },
        fontBackgroundColor: {
          colors: [
            {
              color: '#000000',
            },
            {
              color: '#FFFFFF',
            },
            {
              color: '#FF0000',
            },
            {
              color: '#00FF00',
            },
            {
              color: '#0000FF',
            },
            {
              color: '#FFFF00',
            }, {
              color: '#00FFFF',
            }, {
              color: '#FF00FF',
            },
            {
              color: '#C0C0C0',
            },
            {
              color: '#808080',
            },
            {
              color: '#800000',
            },
            {
              color: '#808000',
            },
            {
              color: '#008000',
            },
            {
              color: '#800080',
            }, {
              color: '#008080',
            }, {
              color: '#000080',
            },
            {
              color: '#3366FF',
            }, {
              color: '#007700',
            }, {
              color: '#330099',
            }, {
              color: '#FF33CC',
            },
            {
              color: '#33CC66',
            }, {
              color: '#FF9900',
            },
            {
              color: '#663300',
            },
            {
              color: '#006699',
            },

            // ...
          ],
          columns: 6,
          documentColors: 0,

          // ...
        },
        image: {
          styles: ["full", "alignLeft", "alignCenter", "alignRight"],
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "imageTextAlternative",
            "imageStyle:full",
            "imageResize"
          ]
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"]
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: process.env.VUE_APP_API_URL + "/upload/image",
          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            Authorization: `Bearer ${JwtService.getToken()}`
          }
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "heading",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: "h1",
              title: "heading1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: "h2",
              title: "heading2",
              class: "ck-heading_heading2"
            },
            {
              model: "heading3",
              view: "h3",
              title: "heading3",
              class: "ck-heading_heading3"
            },
            {
              model: "heading4",
              view: "h4",
              title: "heading4",
              class: "ck-heading_heading4"
            },
            {
              model: "heading5",
              view: "h5",
              title: "heading5",
              class: "ck-heading_heading5"
            },
            {
              model: "heading6",
              view: "h6",
              title: "heading6",
              class: "ck-heading_heading6"
            },

          ]
        },
        mediaEmbed: {
            removeProviders: [
              "instagram",
              "twitter",
              "googleMaps",
              "flickr",
              "facebook",
            ],
            previewsInData: true,
          },
        alignment: {
          options: ["left", "right", "center"]
        },
        ckfinder: {
          // Open the file manager in the pop-up window.
            uploadUrl: process.env.VUE_APP_API_URL + "/upload/image",
          options: {
            resourceType: "Images"
          }
        },
        imageRemoveEvent: {
          additionalElementTypes: null,
          callback: (imagesSrc) => {
            if (imagesSrc) {
              this.$store.dispatch(DELETE_IMAGE, {urls: imagesSrc})
            }
          },
        },
      },
      // dictionary: {
      //   custom: {
      //     title: {
      //       required: () => "タイトルを入力してください。",
      //       max: "タイトルは５０文字以内で入力してください。",
      //     },
      //     editorData: {
      //       required: () => "本文を入力してください。",
      //     }
      //   }
      // },

      isShowConfirmDialog: false,
      validationInputTag: [
        {
          classes: "min-length",
          rule: tag => tag.text.length <= 10
        }
      ],

    };
  }
};
</script>

<style scoped>
/* .ck-content {
  height: var(--height);
} */
</style>
