<template>
  <v-container class="pl-0 pr-0">
    <v-textarea v-model="value" outlined filled :type="type" :rows="rows"
                  @input="$emit('input', value)" :label="label" :name="name" :disabled="disabled"
                  hide-details="auto" dense></v-textarea>
  </v-container>
</template>

<script>

export default {
  props: {
    name: {type: String, default: ''},
    rows: {type: Number, default: 3},
    type: {type: String, default: 'text'},
    color: {type: String, default: ''},
    text: {type: String, default: null},
    label: {type: String, default: null},
    disabled: {type: Boolean, default: false},
    validate: {type: Object, default: null},
    formElement: {type: String, default: ''}
  },
  watch: {
    text: {
      immediate: true,
      handler() {
        this.value = this.text;
      }
    }
  },
  data() {
    return {
      submitted: false,
      value: ""
    }
  },
  components: {},
  methods: {
    onClick() {

    }
  }
}
</script>

<style lang="scss">
@media only screen and (max-width: 600px) {

}

@media only screen and (min-width: 600px) {

}

@media only screen and (min-width: 768px) {

}

@media only screen and (min-width: 992px) {

}

@media only screen and (min-width: 1200px) {
}

</style>
